import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Divider,
  Tooltip,
  IconButton,
  getIconButtonUtilityClass,
  MenuItem,
} from "@mui/material";
import { useRef } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import {
  getPackages,
  getAllPackages,
  createOpd,
  updateOpd,
} from "../../actions/ClientWebAppActions";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import Loader from "../loader";
import SecureStorage from "../../../config/SecureStorage";
import moment from "moment";
interface Props {
  setOpen: Function;
  getPackages: Function;
  packagesList: any;
  getAllPackages: Function;
  allPackages: any;
  loading: any;
  createOpd: Function;
  updateOpd: Function;
  editData: any;
}
const OPDForm: React.FC<Props> = ({
  setOpen,
  getPackages,
  packagesList,
  getAllPackages,
  allPackages,
  loading,
  createOpd,
  updateOpd,
  editData,
}) => {
  const timer = useRef<any>(0);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<any>([]);
  const [selectAllTests, setSelectAllTests] = useState<boolean>(false);
  const [more, setMore] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [opdData, setOpdData] = useState<any>({
    brand_name: "",
    estimate_number_of_user: null,
    plan_start_date: null,
    plan_end_date: null,
    auto_renewal: false,
    login_via: "Mobile",
    services: [],
    member_limit: null,
    wallet_balance: null,
    wallet_top_up: false,
    medicine_included_in_wallet_balance: false,
    medicine_discount_type: null,
    discount_value: null,
    minimum_order_value: null,
    maximum_discount_limit: null,
    delivery_charges: "",
    minimum_order_value_remove_charges: null,
    packages: [],
    is_active: true,
    package_details:[],
    // "member_add_on": "Yes",
    // "discount_mode": "1",
    // "periodic_frequency": "Limit",
    // "discount_percentage": "10.00",
    // "minimum_order_limit": "10.00",
    // "number_of_times_to_avail_discount": 5,
    // "frequency_period": "5",
    // "maximum_discount_on_order_value": "5.00",
    // "free_delivery_charges_order_value": "500.00",
    // "coupon_name": "RCL-HOLI",
    // "brand_logo": "https://static-redcliffelabs.s3.amazonaws.com/media/brand_logos/dextools.png",
    // "offer_title": "Holi offer",
    // "offer_description": "qwerty",
    // "offer_detail": "qwert",
    // "coupon_code": "qwerty",
    // "times_offer_utilized": 1,
    // "offer_link": "http://www.redcliffelabs.com",
    // "shorten_url_generator": "offer",
    // "escalation_contact": "9821422321",
    // "doctor_periodic_frequency": "NoLimit",
    // "doctor_discount_mode": "1",
  });
  console.log(opdData, "kdddd");
  const handleChangeData = (val: any, key: string, checked: any) => {
    if (key === "services") {
      let arr = opdData?.services?[...opdData?.services]:[];
      if (checked) {
        arr = [...arr, val];
      } else {
        arr = arr.filter((f: any) => f !== val);
      }
      setOpdData({ ...opdData, [key]: arr });
    } else {
      setOpdData({ ...opdData, [key]: val });
    }
  };
  useEffect(() => {
    if (editData?.id) {
      setOpdData({...editData,"services":editData.services?editData.services.split(","):[]});
    }
  }, [editData]);
  const handlePackage = (type: any, dat: any) => {
    let arr: any = [...opdData?.packages];

    if (type === true) {
      arr.push(dat);
    } else if (type === false) {
      arr.map((val: any, index: any) => {
        if (val.id === dat.id) {
          arr.splice(index, 1);
        }
      });
    }
    handleChangeData(arr, "packages", false);
  };
  useEffect(() => {
    getPackages();
    getAllPackages();
  }, []);
  useEffect(() => {
    setData(packagesList?.data || []);
  }, [packagesList]);
  useEffect(() => {
    if (packagesList?.data) {
      let arr2: any = [...packagesList?.data];
      let arr: any = [...opdData.packages];
      if (arr2.length > 0) {
        arr2.map((y: any, index: any) =>
          arr.length > 0
            ? arr.filter((val: any) => y.id === val.id).length > 0
              ? (arr2[index]["selected"] = true)
              : (arr2[index]["selected"] = false)
            : (arr2[index]["selected"] = false)
        );
        setData(arr2);
      }
    }
  }, [opdData.packages, packagesList]);
  // console.log(data, "kd");
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = packagesList?.links && packagesList?.links.next.split("?")[1];
      getPackages(url);
    } else if (newPage < page) {
      let url =
        packagesList?.links && packagesList?.links.previous.split("?")[1];
      getPackages(url);
    }
    setPage(newPage as number);
  };
  const handleSearch = (val: any) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      getPackages(`search=${val}`);
    }, 700);
  };

  const submitOpd = async (e:any) => {
    e.preventDefault()
    setSubmitted(true);
    const formData = new FormData();
    formData.append("brand_name", opdData.brand_name);
    formData.append("center", SecureStorage.getItem("centre"));
    formData.append("estimate_number_of_user", opdData.estimate_number_of_user);
    formData.append("plan_start_date", opdData.plan_start_date);
    formData.append("plan_end_date", opdData.plan_end_date);
    formData.append("auto_renewal", opdData.auto_renewal);
    formData.append("is_active", opdData.is_active);
    formData.append("login_via", opdData.login_via);
    formData.append("services", opdData.services);
    formData.append("member_limit", opdData.member_limit);
    formData.append("wallet_balance", opdData.wallet_balance);
    formData.append(
      "medicine_included_in_wallet_balance",
      opdData.medicine_included_in_wallet_balance
    );
    formData.append("medicine_discount_type", opdData.medicine_discount_type);
    formData.append("discount_value", opdData.discount_value);
    formData.append("minimum_order_value", opdData.minimum_order_value);
    formData.append("maximum_discount_limit", opdData.maximum_discount_limit);
    formData.append("delivery_charges", opdData.delivery_charges);
    formData.append(
      "minimum_order_value_remove_charges",
      opdData.minimum_order_value_remove_charges
    );
    opdData.packages.length > 0 &&
      opdData.packages.map((val: any) =>
        formData.append("packages", val.id ? val.id : val)
      );
    if (editData.id) {
      await updateOpd(formData, editData.id);
      setSubmitted(true);
      window.location.reload();
    } else {
      await createOpd(formData);
      setSubmitted(true);
      window.location.reload();
    }
  };
  return (
    <main style={{ padding: "20px", margin: "0px 25px" }}>
      <form onSubmit={submitOpd}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ textAlign: "right", marginBottom: "-20px" }}
        >
          {" "}
          <Tooltip title="Close">
            <IconButton aria-label="delete">
              <CloseRoundedIcon onClick={() => setOpen(false)} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <h4>Basic Details</h4>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            key={editData?.id}
            InputLabelProps={{
              shrink: true,
            }}
            value={opdData.brand_name}
            onChange={(e) =>
              handleChangeData(e.target.value, "brand_name", false)
            }
            label="Template Name"
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            key={editData?.id}
            InputLabelProps={{
              shrink: true,
            }}
            value={opdData.estimate_number_of_user}
            onChange={(e) =>
              handleChangeData(
                e.target.value.replace(/[^0-9]/g, ""),
                "estimate_number_of_user",
                false
              )
            }
            label="Estimate Number of User"
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            key={editData?.id}
            InputLabelProps={{
              shrink: true,
            }}
            value={opdData.plan_start_date}
            onChange={(e) =>
              handleChangeData(e.target.value, "plan_start_date", false)
            }
            label="Start date"
            type="date"
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            key={editData?.id}
            InputLabelProps={{
              shrink: true,
            }}
            value={opdData.plan_end_date}
            inputProps={{
              min: moment(opdData.plan_start_date).add(1, "days").format("YYYY-MM-DD"),
            }}
            onChange={(e) =>
              handleChangeData(e.target.value, "plan_end_date", false)
            }
            label="End date"
            type="date"
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControlLabel
            style={{ marginBottom: "-20px" }}
            labelPlacement="start"
            control={
              <Checkbox
                checked={opdData.auto_renewal}
                onChange={(e) =>
                  handleChangeData(e.target.checked, "auto_renewal", false)
                }
              />
            }
            label="Auto Renewal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControlLabel
            style={{ marginBottom: "-20px" }}
            labelPlacement="start"
            control={
              <Checkbox
                checked={opdData.is_active}
                onChange={(e) =>
                  handleChangeData(e.target.checked, "is_active", false)
                }
              />
            }
            label="Is Active"
          />
        </Grid>
        <Grid item xs={12}>
          <h4>User Configuration </h4>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Login Via
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={opdData.login_via}
              onChange={(e) =>
                handleChangeData(e.target.value, "login_via", false)
              }
            >
              <FormControlLabel
                value={"Email"}
                control={<Radio />}
                label="Email"
                // onClick={() => setIsPkgLivePrice(true)}
              />
              {/* <FormControlLabel
                value={"EmployeeID"}
                control={<Radio />}
                label="Email with data"
                // onClick={() => setIsPkgLivePrice(false)}
              /> */}
              <FormControlLabel
                value={"Mobile"}
                control={<Radio />}
                label="Mobile"
                // onClick={() => setIsPkgLivePrice(false)}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Choose Services(s)*
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={"doctor_consultation"}
                    checked={opdData?.services&&opdData?.services?.some(
                      (val: any) => val === "doctor_consultation"
                    )}
                    onChange={(e) =>
                      handleChangeData(
                        e.target.value,
                        "services",
                        e.target.checked
                      )
                    }
                  />
                }
                label="Doctor Consultation"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"diagonastic_booking"}
                    checked={opdData?.services&&opdData?.services?.some(
                      (val: any) => val === "diagonastic_booking"
                    )}
                    onChange={(e) =>
                      handleChangeData(
                        e.target.value,
                        "services",
                        e.target.checked
                      )
                    }
                  />
                }
                label="Diagnostic Booking"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"order_medicine"}
                    checked={opdData?.services&&opdData?.services?.some(
                      (val: any) => val === "order_medicine"
                    )}
                    onChange={(e) =>
                      handleChangeData(
                        e.target.value,
                        "services",
                        e.target.checked
                      )
                    }
                  />
                }
                label="Order Medicine"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Wallet Top Up
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={opdData.wallet_top_up}
              onChange={(e) =>
                handleChangeData(e.target.value, "wallet_top_up", false)
              }
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Yes"
                // onClick={() => setIsPkgLivePrice(true)}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
                // onClick={() => setIsPkgLivePrice(false)}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            label="Wallet Balance"
            fullWidth
            value={opdData.wallet_balance}
            onChange={(e) =>
              handleChangeData(e.target.value.replace(/[^0-9]/g, ""), "wallet_balance", false)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            
            label="Member limit"
            fullWidth
            value={opdData.member_limit}
            onChange={(e) =>
              handleChangeData(e.target.value.replace(/[^0-9]/g, ""), "member_limit", false)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Medicine Configuration</h4>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Medicine Included ni Wallet Balance
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={opdData.medicine_included_in_wallet_balance}
              onChange={(e) =>
                handleChangeData(
                  e.target.value,
                  "medicine_included_in_wallet_balance",
                  false
                )
              }
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Yes"
                // onClick={() => setIsPkgLivePrice(true)}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
                // onClick={() => setIsPkgLivePrice(false)}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Medicine Discount Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={opdData.medicine_discount_type}
              onChange={(e) =>
                handleChangeData(
                  e.target.value,
                  "medicine_discount_type",
                  false
                )
              }
            >
              <FormControlLabel
                value={"flat"}
                control={<Radio />}
                label="Flat"
                // onClick={() => setIsPkgLivePrice(true)}
              />
              <FormControlLabel
                value={"percentage"}
                control={<Radio />}
                label="Percentage"
                // onClick={() => setIsPkgLivePrice(false)}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            label="Discount Value"
            fullWidth
            value={opdData.discount_value}
            onChange={(e) =>
              handleChangeData(e.target.value.replace(/[^0-9]/g, ""), "discount_value", false)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            label="Minimum Order Value"
            fullWidth
            value={opdData.minimum_order_value}
            onChange={(e) =>
              handleChangeData(e.target.value.replace(/[^0-9]/g, ""), "minimum_order_value", false)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            label="Maximum Discount Limit"
            fullWidth
            value={opdData.maximum_discount_limit}
            onChange={(e) =>
              handleChangeData(e.target.value.replace(/[^0-9]/g, ""), "maximum_discount_limit", false)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            label="Delivery Charges"
            fullWidth
            value={opdData.delivery_charges}
            onChange={(e) =>
              handleChangeData(e.target.value.replace(/[^0-9]/g, ""), "delivery_charges", false)
            }
          />
        </Grid>{" "}
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            label="Minimum Order Value Remove Charges"
            fullWidth
            value={opdData.minimum_order_value_remove_charges}
            onChange={(e) =>
              handleChangeData(
                e.target.value.replace(/[^0-9]/g, ""),
                "minimum_order_value_remove_charges",
                false
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item container>
            <h4>Package selection</h4>
            {selectAllTests ? (
              <Grid item xs={12} style={{ marginBottom: 25 }}>
                {packagesList && packagesList?.count ? packagesList?.count : 0}{" "}
                packages/test selected.
              </Grid>
            ) : (
              ""
            )}
            <Grid
              item
              xs={12}
              style={{
                marginBottom: 25,
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {!selectAllTests &&
                opdData.package_details?.length > 0 &&
                opdData.package_details.map((val: any, index: any) => {
                  return index < 3 && !more ? (
                    <div className="customChip">
                      <div style={{ width: "80%", padding: "0px 10px" }}>
                        {String(val?.name).substring(0, 25) +
                          (String(val?.name).length > 25 ? "..." : "")}
                      </div>
                      <div style={{ width: "20%", textAlign: "center" }}>
                        <ClearRoundedIcon
                          onClick={() => handlePackage(false, val)}
                        />
                      </div>
                    </div>
                  ) : more ? (
                    <div className="customChip">
                      <div style={{ width: "80%", padding: "0px 10px" }}>
                        {String(val?.name).substring(0, 25) +
                          (String(val?.name).length > 25 ? "..." : "")}
                      </div>
                      <div style={{ width: "20%", textAlign: "center" }}>
                        <ClearRoundedIcon
                          onClick={() => handlePackage(false, val)}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  );
                })}
              {!selectAllTests && opdData.package_details?.length >= 4 && (
                <div
                  className="customChip"
                  style={{ cursor: "pointer" }}
                  onClick={() => setMore(!more)}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 10px",
                      color: "#F02632",
                      textAlign: "center",
                    }}
                  >
                    {!more ? (
                      <>
                        View More <AddRoundedIcon />
                      </>
                    ) : (
                      <>
                        View Less <RemoveRoundedIcon />
                      </>
                    )}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer
              className={"tableContainer"}
              style={{ height: "45vh" }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: 53 }}>
                      SELECT ALL
                      <br />
                      <Checkbox
                        disabled={
                          allPackages?.packages?.length === 0 ? true : false
                        }
                        checked={selectAllTests}
                        style={{ padding: 0 }}
                        color="success"
                        onChange={(e) => {
                          setSelectAllTests(e.target.checked);
                          e.target.checked === true
                            ? handleChangeData(
                                allPackages?.packages || [],
                                "packages",
                                false
                              )
                            : handleChangeData([], "packages", false);
                        }}
                      />
                    </TableCell>
                    <TableCell align="left" style={{ width: 250 }}>
                      PACKAGE NAME
                      <br />
                      <TextField
                        placeholder="Search Packages"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleSearch(e.target.value)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="center">DESCRIPTION</TableCell>
                    <TableCell align="center">MRP</TableCell>
                    <TableCell align="center">OFFER PRICE</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableBody>
                    <TableRow>
                      <td></td>
                      <td>
                        <Loader />
                      </td>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody>
                      {data &&
                        data?.length > 0 &&
                        data.map((val: any) => {
                          return (
                            <TableRow>
                              <TableCell align="center">
                                {val?.selected === true || selectAllTests ? (
                                  <IconButton
                                    disabled={selectAllTests}
                                    onClick={() => handlePackage(false, val)}
                                  >
                                    <CheckBoxRoundedIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => handlePackage(true, val)}
                                  >
                                    <CheckBoxOutlineBlankRoundedIcon />
                                  </IconButton>
                                )}
                                {/* <Checkbox
                                      defaultChecked={
                                        val.selected ? true : false
                                      }
                                      onChange={(e) =>
                                        handlePackage(e.target.checked, val)
                                      }
                                    /> */}
                              </TableCell>
                              <TableCell align="left">{val?.name}</TableCell>
                              <TableCell align="center">
                                {val?.description}
                              </TableCell>
                              <TableCell align="center">
                                {val?.package_center_prices.package_price}
                              </TableCell>
                              <TableCell align="center">
                                {val?.package_center_prices.offer_price}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        {packagesList?.count > 0 ? (
                          <TablePagination
                            rowsPerPageOptions={[]}
                            count={packagesList?.count || 0}
                            rowsPerPage={packagesList?.page_size}
                            page={page}
                            onPageChange={handleChangePage}
                          />
                        ) : (
                          "No Data Found!"
                        )}
                      </TableRow>
                    </TableFooter>
                  </>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} textAlign="center">
          {loading && submitted ? (
            <>
              <Loader />
              <br /> {editData.id ? "Updating" : "Submitting"}
            </>
          ) : (
            <>
              {" "}
              <Button
                variant="outlined"
                color="success"
                type="submit"
                // onClick={() => submitOpd()}
                style={{ width: "150px" }}
                disabled={
                  opdData.packages.length === 0 ||
                  !opdData.brand_name ||
                  !opdData.plan_start_date ||
                  !opdData.plan_end_date||
                  opdData?.services?.length===0||!opdData.estimate_number_of_user
                }
              >
                {editData.id ? "update" : "Submit"}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      </form>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  packagesList: state.ClientWebAppReducer.packagesList,
  allPackages: state.ClientWebAppReducer.allPackages,
  loading: state.ClientWebAppReducer.loading,
});

export default connect(mapStateToProps, {
  getPackages,
  getAllPackages,
  createOpd,
  updateOpd,
})(OPDForm);
