import React, { FunctionComponent, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  resetPasswordReset,
  sendOtp,
} from "../../actions/loginActions";
import { useEffect } from "react";

type FormData = {
  email: string;
  password: string;
};

type LoginFormProps = {
  handleForm: any;
  loading: boolean;
};

const LoginForm: FunctionComponent<LoginFormProps> = ({
  handleForm,
  loading,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const loginReducer = useSelector((state: any) => state.loginReducer);
  const { otp: otpSent, success, loading: loadingReset } = loginReducer;

  const [forgot, setForgot] = useState<any>(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");

  const sendOtpHandler = () => {
    dispatch(sendOtp({ email }));
  };

  const resetPasswordHandler = async () => {
    const body: any = {
      token: otp,
      password,
    };
    if (otp && password) {
      dispatch(resetPassword(body));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (success) {
      navigate("/");
    }
  }, [navigate, success]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(resetPasswordReset());
  }, [dispatch]);

  const resetDetails = () => {
    setForgot(false);
    setEmail("");
    setOtp("");
    setPassword("");
    dispatch(resetPasswordReset());
  };

  return (
    <Container component="main" maxWidth="xs">
      <div>
       
        {!forgot ? (
          <form onSubmit={handleSubmit(handleForm)} noValidate>
            <TextField
              className="input"
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              // name="email_id"
              autoComplete="email"
              autoFocus
              {...register("email", {
                required: "Email is Required",
              })}
              required={errors.email ? true : false}
              error={errors.email ? true : false}
              helperText={errors.email && errors.email.message}
            />
            <TextField
              className="input"
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("password", {
                required: "Password is Required",
              })}
              required={errors.password ? true : false}
              error={errors.password ? true : false}
              helperText={errors.password && errors.password.message}
            />
             <div style={{ marginTop: "1rem" }}>
              <p
                style={{textAlign:"right",color:"#2677F0"}}
                onClick={() => setForgot(true)}
              >
                Forgot Password?
              </p>
            </div>
            <div style={{ marginTop: "1rem",textAlign:"center" }}>
              <LoadingButton
                type="submit"
                loadingPosition="center"
                variant="contained"
                style={{background:"#1F1F1F",borderRadius:"8px",width:"245px"}}
                loading={loading}
              >
                Sign In
              </LoadingButton>
           
            </div>
           
          </form>
        ) : (
          <>
            <div style={{textAlign:"center"}}>
              <TextField
                className="input"
                name="email"
                type="email"
                variant="outlined"
                margin="normal"
                fullWidth
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  width: "100%",
                  margin: "1rem auto",
                  padding: "14px 14px",
                }}
              />
              <div>
                <Button
                   style={{borderRadius:"8px",width:"245px"}}
                  variant="contained"
                  color="primary"
                  disabled={loadingReset || !email}
                  onClick={() => sendOtpHandler()}
                >
                  Send OTP
                </Button>
                {loadingReset && <CircularProgress size={24} />}
              </div>
              <TextField
                className="input"
                name="password"
                type="password"
                variant="outlined"
                margin="normal"
                fullWidth
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "100%",
                  margin: "0px auto",
                  padding: "14px 14px",
                }}
                disabled={!(otpSent && otpSent.status)}
              />
              <TextField
                className="input"
                name="otp"
                type="password"
                placeholder="OTP"
                variant="outlined"
                margin="normal"
                fullWidth
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                style={{
                  width: "100%",
                  margin: "0px auto",
                  padding: "14px 14px",
                }}
                disabled={!(otpSent && otpSent.status)}
              />
              <div>
                <Button
                  
                  variant="contained"
                  color="primary"
                  style={{borderRadius:"8px",width:"245px"}}
                  disabled={
                    loadingReset ||
                    !(otpSent && otpSent.status) ||
                    !otp ||
                    !password
                  }
                  onClick={() => resetPasswordHandler()}
                >
                  Update Password
                </Button>
                {loadingReset && <CircularProgress size={24} />}
              </div>
              <div>
                <Button
                   style={{borderRadius:"8px",width:"245px",marginTop:"10px"}}
                  variant="contained"
                  color="secondary"
                  onClick={() => resetDetails()}
                >
                  Login
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default LoginForm;
