import { ACTIONS } from "../interfaces/actionTypes/ClientWebApp";
import AXIOS from "../../config/Axios";
import Prefix from "../../config/ApiPrefix";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";

export const changePassword = (body: any) => async (dispatch: Function) => {
  try {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    await AXIOS.post(
      `${Prefix.api}/auth/password_reset/reset_password/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.CHANGE_PASSWORD, payload: null });
    snackBarUpdate({
      payload: {
        message: "Password Updated",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const uploadCampUsers =
  (file: any, camp: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `api/external/v2/upload-iframe-data-n/`,
        file,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPLOAD_CAMP_USER, payload: data });
      snackBarUpdate({
        payload: {
          message: "Uploaded Succesfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response?.data?.detail) {
        title = JSON.stringify(err.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  };

export const getUserDetails = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/auth/shortprofile/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getCwa = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `/api/external/v2/center-create-webapp/${url ? "?" + url : ""}`,
      {
        headers: { key: `${SecureStorage.getItem("key")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_CWA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_CWA, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getOpd = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `api/external/v1/diagnostic-booking-configuration/${
        url ? "?" + url : ""
      }`,
      {
        headers: { key: `${SecureStorage.getItem("key")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_OPD, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_OPD, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const createOpd = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `/api/external/v1/diagnostic-booking-configuration/`,
      body,
      {
        headers: { key: `${SecureStorage.getItem("key")}` },
      }
    );
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    snackBarUpdate({
      payload: {
        message: "CWA Created successfully ",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateOpd =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `/api/external/v1/diagnostic-booking-configuration/${id}/`,
        bookingData,
        {
          headers: { key: `${SecureStorage.getItem("key")}` },
        }
      );
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      snackBarUpdate({
        payload: {
          message: "Successfully Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);

      throw err;
    }
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  };
export const createCwa = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `/api/external/v2/center-create-webapp/`,
      body,
      {
        headers: { key: `${SecureStorage.getItem("key")}` },
      }
    );
    dispatch({ type: ACTIONS.CREATE_CWA, payload: data });
    snackBarUpdate({
      payload: {
        message: "CWA Created successfully ",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateCwa =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `/api/center/v2/center3p/${id}/`,
        bookingData,
        {
          headers: { key: `${SecureStorage.getItem("key")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_CWA, payload: data });
      snackBarUpdate({
        payload: {
          message: "Successfully Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);

      throw err;
    }
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  };
export const getPackages = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `api/external/v2/center-package-data/${url ? "?" + url : ""}`,
      {
        headers: { key: `${SecureStorage.getItem("key")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_PACKAGES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    if (err.response.data?.message) {
      title = JSON.stringify(err.response.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);

    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAllPackages = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `api/external/v2/get-all-webapp/${url ? "?" + url : ""}`,
      {
        headers: { key: `${SecureStorage.getItem("key")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_PACKAGES_ALL, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    if (err.response.data?.message) {
      title = JSON.stringify(err.response.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);

    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getCenterInfo = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/center/dc_gelab/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getDocAppoinments =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `api/v1/external/doctor_appointments/${url ? "?" + url : ""}`,
        {
          headers: { key: `${SecureStorage.getItem("key")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_DOC_APPOINMENT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.GET_OPD, payload: [] });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
