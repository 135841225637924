import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Divider,
  Tooltip,
  IconButton,
  getIconButtonUtilityClass,
} from "@mui/material";
import { useRef } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import {
  getPackages,
  getAllPackages,
  createCwa,
  updateCwa,
} from "../../actions/ClientWebAppActions";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import Loader from "../loader";
import SecureStorage from "../../../config/SecureStorage";
import moment from "moment";
interface Props {
  setOpen: Function;
  getPackages: Function;
  packagesList: any;
  getAllPackages: Function;
  allPackages: any;
  loading: any;
  createCwa: Function;
  updateCwa: Function;
  editData: any;
}
const CWAForm: React.FC<Props> = ({
  setOpen,
  getPackages,
  packagesList,
  getAllPackages,
  allPackages,
  loading,
  createCwa,
  updateCwa,
  editData,
}) => {
  const timer = useRef<any>(0);
  const [page, setPage] = useState<number>(0);
  const [tab, setTab] = useState<number>(0);
  const [packages, setPackages] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [noEndDate, setNoEndDate] = useState<boolean>(false);
  const [selectAllTests, setSelectAllTests] = useState<boolean>(false);
  const [more, setMore] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [logo, setLogo] = useState<any>("");
  const [campName, setCampName] = useState<string>("");
  const [reschedule, setReschedule] = useState<any>("none");
  const [showDataUpload, setShowDataUpload] = useState<any>("none");
  const [isCaptureLead, setIsCaptureLead] = useState<any>("none");
  const [showEmpId, setShowEmpId] = useState<any>("none");
  const [cancellation, setCancellation] = useState<any>("none");
  const [additionalMember, setAdditionalMember] = useState<any>("none");
  const [applyCouponCode, setApplyCouponCode] = useState<any>("none");
  const [prescription, setPrescription] = useState<any>("none");
  const [NPSRating, setNPSRating] = useState<any>("none");
  const [OTPAuthenticator, setOTPAuthenticator] = useState<string>("");
  const [customerSupport, setCustomerSupport] = useState<string>("");
  const [customerSupportBy, setCustomerSupportBy] = useState<string>("");
  const [customerSupportNo, setCustomerSupportNo] = useState<string>("");
  const [paymentMode, setPaymentMode] = useState<any>("none");
  const [paymentModeType1, setPaymentModeType1] = useState<string>("");
  const [paymentModeType2, setPaymentModeType2] = useState<string>("");
  const [limit, setLimit] = useState<any>("none");
  const [noOfBookings, setNoOfBookings] = useState<any>("");
  const [period, setPeriod] = useState<any>("none");
  const [showLivePrice, setShowLivePrice] = useState<any>(true);
  const [loginVia, setLoginVia] = useState<any>("EM");
  const [showMrpSummary, setShowMrpSummary] = useState<any>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isPkgMrp, setIsPkgMrp] = useState<any>(true);
  const [isPkgLivePrice, setIsPkgLivePrice] = useState<any>(true);
  const [periodDays, setPeriodDays] = useState<any>("");
  const [ecg_present, setEcg_Present] = useState<boolean>(false);

  useEffect(() => {
    if (editData?.id) {
      setLimit(editData?.booking_limit);
      setNoOfBookings(editData?.booking_limit_count);
      setPeriodDays(editData?.booking_limit_days);
      if (editData?.booking_limit_days < 99999) {
        setPeriod(false);
      } else if (editData?.booking_limit_days === 99999) {
        setPeriod(true);
      }
      if (editData?.payment_mode === "BOTH") {
        setPaymentModeType1("POST");
        setPaymentModeType2("PRE");
      } else if (editData?.payment_mode === "POST") {
        setPaymentModeType1("POST");
      } else if (editData?.payment_mode === "PRE") {
        setPaymentModeType2("PRE");
      }
      setPaymentMode(editData?.is_subscription);
      setPaymentMode(editData?.is_subscription);
      setCustomerSupportNo(editData?.support_contact);
      setCustomerSupportBy(editData?.support_enabled_using);
      setCustomerSupport(editData?.support_medium);
      setOTPAuthenticator(editData?.otp_mode);
      setNPSRating(editData?.nps_rating);
      setPrescription(editData?.can_upload_prescription);
      setApplyCouponCode(editData?.can_create_coupon);
      setAdditionalMember(editData?.can_add_additional_member);
      setCancellation(editData?.can_cancel);
      setReschedule(editData?.can_reschedule);
      setLoginVia(editData?.login_via);
      setShowDataUpload(editData?.allow_upload);
      setIsCaptureLead(editData?.capture_lead);
      setShowEmpId(editData?.show_empId);
      setCampName(editData?.name);
      setStartDate(String(editData?.expire_start_time).slice(0, 16) || "");
      setEndDate(
        editData?.expire_end_time
          ? String(editData?.expire_end_time).slice(0, 16)
          : ""
      );
      setPackages(editData?.package_details || []);
      if (!editData?.expire_end_time) {
        setNoEndDate(true);
      }
      setIsPkgMrp(editData?.show_package_mrp);
      setIsPkgLivePrice(editData?.show_package_golive);
      setShowMrpSummary(editData?.show_mrp_summary);
      setShowLivePrice(editData?.show_golive_summary_price);
    }
  }, [editData]);
  const handlePackage = (type: any, dat: any) => {
    let arr: any = [...packages];

    if (type === true) {
      arr.push(dat);
    } else if (type === false) {
      arr.map((val: any, index: any) => {
        if (val.id === dat.id) {
          arr.splice(index, 1);
        }
      });
    }
    setPackages(arr);
  };
  useEffect(() => {
    getPackages();
    getAllPackages();
  }, []);
  useEffect(() => {
    setData(packagesList?.data || []);
  }, [packagesList]);
  useEffect(() => {
    if (packagesList?.data) {
      let arr2: any = [...packagesList?.data];
      let arr: any = [...packages];
      if (arr2.length > 0) {
        arr2.forEach((y: any, index: any) => {
          if (arr.length > 0) {
            const match = arr.find((val: any) => y.id === val.id);
            if (match) {
              arr2[index]["selected"] = true;
            } else {
              arr2[index]["selected"] = false;
            }
          } else {
            arr2[index]["selected"] = false;
          }
        });
        setData(arr2);
      }
    }
    const isECG = packages?.some(
      (v: any) => v?.package_center_prices?.is_ecg || v?.is_ecg
    );
    setEcg_Present(isECG);
  }, [packages, packagesList]);
  // console.log(data, "kd");
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = packagesList?.links && packagesList?.links.next.split("?")[1];
      getPackages(url);
    } else if (newPage < page) {
      let url =
        packagesList?.links && packagesList?.links.previous.split("?")[1];
      getPackages(url);
    }
    setPage(newPage as number);
  };
  const handleSearch = (val: any) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      getPackages(`search=${val}`);
    }, 700);
  };

  const submitCwa = async () => {
    setSubmitted(true);
    const formData = new FormData();
    formData.append("name", campName);
    packages.length > 0 &&
      packages.map((val: any) =>
        formData.append("packages", val.id ? val.id : val)
      );

    formData.append("center", SecureStorage.getItem("centre"));
    formData.append("expire_start_time", startDate);
    if (!noEndDate) {
      formData.append("expire_end_time", endDate);
    }
    if (logo !== "") {
      formData.append("logo_image", logo);
    }
    //config
    reschedule !== "none" && formData.append("can_reschedule", reschedule);
    formData.append("login_via", loginVia);
    showDataUpload !== "none" &&
      formData.append("allow_upload", showDataUpload);
    isCaptureLead !== "none" && formData.append("capture_lead", isCaptureLead);
    showEmpId !== "none" && formData.append("show_empId", showEmpId);
    cancellation !== "none" && formData.append("can_cancel", cancellation);
    additionalMember !== "none" &&
      formData.append("can_add_additional_member", additionalMember);
    applyCouponCode !== "none" &&
      formData.append("can_create_coupon", applyCouponCode);
    prescription !== "none" &&
      formData.append("can_upload_prescription", prescription);
    NPSRating !== "none" && formData.append("nps_rating", NPSRating);
    OTPAuthenticator !== "" && formData.append("otp_mode", OTPAuthenticator);
    customerSupport !== "" &&
      formData.append("support_medium", customerSupport);
    customerSupportBy !== "" &&
      formData.append("support_enabled_using", customerSupportBy);
    customerSupportNo !== "" &&
      formData.append("support_contact", customerSupportNo);
    paymentMode !== "none" && formData.append("is_subscription", paymentMode);
    showMrpSummary !== "" &&
      formData.append("show_mrp_summary", showMrpSummary);
    showLivePrice !== "" &&
      formData.append("show_golive_summary_price", showLivePrice);
    isPkgMrp !== "" && formData.append("show_package_mrp", isPkgMrp);
    isPkgLivePrice !== "" &&
      formData.append("show_package_golive", isPkgLivePrice);
    limit !== "none" && formData.append("booking_limit", limit);
    if (limit === true) {
      formData.append("booking_limit_count", noOfBookings);
      if (period === false) {
        formData.append("booking_limit_days", periodDays);
      }
      if (period === true) {
        formData.append("booking_limit_days", "99999");
      }
    }
    if (paymentMode === true) {
      formData.append("payment_mode", "PRE");
    } else if (paymentMode === false) {
      if (paymentModeType1 === "POST" && paymentModeType2 === "PRE") {
        formData.append("payment_mode", "BOTH");
      } else if (paymentModeType1 === "POST" && paymentModeType2 === "") {
        formData.append("payment_mode", "POST");
      } else if (paymentModeType1 === "" && paymentModeType2 === "PRE") {
        formData.append("payment_mode", "PRE");
      }
    }
    if (editData.id) {
      await updateCwa(formData, editData.id);
      setSubmitted(true);
      window.location.reload();
    } else {
      await createCwa(formData);
      setSubmitted(true);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (showDataUpload) {
      setOTPAuthenticator("PA");
    }
  }, [showDataUpload]);

  return (
    <main style={{ padding: "20px", margin: "0px 25px" }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ textAlign: "right", marginBottom: "-20px" }}
        >
          {" "}
          <Tooltip title="Close">
            <IconButton aria-label="delete">
              <CloseRoundedIcon onClick={() => setOpen(false)} />
            </IconButton>
          </Tooltip>
        </Grid>
        {tab === 0 ? (
          <>
            <Grid item xs={12}>
              <h4>Details</h4>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <TextField
                key={editData?.id}
                InputLabelProps={{
                  shrink: true,
                }}
                value={campName}
                onChange={(e) => setCampName(e.target.value)}
                label="Campaign name"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: "image/*",
                }}
                required
                variant="outlined"
                fullWidth
                label="Upload logo"
                type="file"
                onChange={(e: any) =>
                  setLogo(
                    e.target?.files && e.target?.files.length > 0
                      ? e.target.files[0]
                      : ""
                  )
                }
              />
              {/* <label htmlFor="raised-button-file" style={{ width: "100%" }}>
          <span style={{color:"rgba(0, 0, 0, 0.6)",fontSize:"12px",marginLeft:"13px",fontWeight:"bold"}}>*</span>
            <Button
              startIcon={<AttachFileOutlinedIcon />}
              variant="outlined"
              color="primary"
              component="span"
              size="large"
              fullWidth
            >
              Attach File
            </Button>
          </label> */}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                key={editData?.id}
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label="Start date & time"
                type="datetime-local"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} textAlign="right">
              <TextField
                key={editData?.id}
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label="End date & time"
                type="datetime-local"
                variant="outlined"
                fullWidth
                required
              />
              <FormControlLabel
                style={{ marginBottom: "-20px" }}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={noEndDate}
                    onChange={(e) => {
                      setNoEndDate(e.target.checked);
                      e.target.checked && setEndDate("");
                    }}
                  />
                }
                label="No End Date"
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item container>
                <Grid item xs={12} style={{ marginBottom: 25 }}>
                  Package selection
                </Grid>
                {selectAllTests ? (
                  <Grid item xs={12} style={{ marginBottom: 25 }}>
                    {packagesList && packagesList?.count
                      ? packagesList?.count
                      : 0}{" "}
                    packages/test selected.
                  </Grid>
                ) : (
                  ""
                )}

                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: 25,
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {!selectAllTests &&
                    packages?.length > 0 &&
                    packages.map((val: any, index: any) => {
                      return index < 3 && !more ? (
                        <div className="customChip">
                          <div style={{ width: "80%", padding: "0px 10px" }}>
                            {String(val?.name).substring(0, 25) +
                              (String(val?.name).length > 25 ? "..." : "")}
                          </div>
                          <div style={{ width: "20%", textAlign: "center" }}>
                            <ClearRoundedIcon
                              onClick={() => handlePackage(false, val)}
                            />
                          </div>
                        </div>
                      ) : more ? (
                        <div className="customChip">
                          <div style={{ width: "80%", padding: "0px 10px" }}>
                            {String(val?.name).substring(0, 25) +
                              (String(val?.name).length > 25 ? "..." : "")}
                          </div>
                          <div style={{ width: "20%", textAlign: "center" }}>
                            <ClearRoundedIcon
                              onClick={() => handlePackage(false, val)}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  {!selectAllTests && packages?.length >= 4 && (
                    <div
                      className="customChip"
                      style={{ cursor: "pointer" }}
                      onClick={() => setMore(!more)}
                    >
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 10px",
                          color: "#F02632",
                          textAlign: "center",
                        }}
                      >
                        {!more ? (
                          <>
                            View More <AddRoundedIcon />
                          </>
                        ) : (
                          <>
                            View Less <RemoveRoundedIcon />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer
                  className={"tableContainer"}
                  style={{ height: "45vh" }}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ width: 53 }}>
                          SELECT ALL
                          <br />
                          <Checkbox
                            disabled={
                              allPackages?.packages?.length === 0 ? true : false
                            }
                            checked={selectAllTests}
                            style={{ padding: 0 }}
                            color="success"
                            onChange={(e) => {
                              setSelectAllTests(e.target.checked);
                              e.target.checked === true
                                ? setPackages(allPackages?.packages || [])
                                : setPackages([]);
                            }}
                          />
                        </TableCell>
                        <TableCell align="left" style={{ width: 250 }}>
                          PACKAGE NAME
                          <br />
                          <TextField
                            placeholder="Search Packages"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => handleSearch(e.target.value)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="center">DESCRIPTION</TableCell>
                        <TableCell align="center">Ecg</TableCell>
                        <TableCell align="center">MRP</TableCell>
                        <TableCell align="center">OFFER PRICE</TableCell>
                      </TableRow>
                    </TableHead>
                    {loading ? (
                      <TableBody>
                        <TableRow>
                          <td></td>
                          <td>
                            <Loader />
                          </td>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <>
                        <TableBody>
                          {data &&
                            data?.length > 0 &&
                            data.map((val: any) => {
                              return (
                                <TableRow>
                                  <TableCell align="center">
                                    {val?.selected === true ||
                                    selectAllTests ? (
                                      <IconButton
                                        disabled={selectAllTests}
                                        onClick={() =>
                                          handlePackage(false, val)
                                        }
                                      >
                                        <CheckBoxRoundedIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => handlePackage(true, val)}
                                      >
                                        <CheckBoxOutlineBlankRoundedIcon />
                                      </IconButton>
                                    )}
                                    {/* <Checkbox
                                      defaultChecked={
                                        val.selected ? true : false
                                      }
                                      onChange={(e) =>
                                        handlePackage(e.target.checked, val)
                                      }
                                    /> */}
                                  </TableCell>

                                  <TableCell align="left">
                                    {val?.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {val?.description}
                                  </TableCell>
                                  <TableCell align="center">
                                    {String(val?.is_ecg)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {val?.package_center_prices.package_price}
                                  </TableCell>
                                  <TableCell align="center">
                                    {val?.package_center_prices.offer_price}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            {packagesList?.count > 0 ? (
                              <TablePagination
                                rowsPerPageOptions={[]}
                                count={packagesList?.count || 0}
                                rowsPerPage={packagesList?.page_size}
                                page={page}
                                onPageChange={handleChangePage}
                              />
                            ) : (
                              "No Data Found!"
                            )}
                          </TableRow>
                        </TableFooter>
                      </>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setTab(1)}
                style={{ width: "150px" }}
              >
                next
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <h4>Configuration </h4>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Upload Data
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      showDataUpload !== "none" ? (
                        <Radio checked={showDataUpload} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setShowDataUpload(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      showDataUpload !== "none" ? (
                        <Radio checked={!showDataUpload} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setShowDataUpload(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Reschedule
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      reschedule !== "none" ? (
                        <Radio checked={reschedule} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setReschedule(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      reschedule !== "none" ? (
                        <Radio checked={!reschedule} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setReschedule(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Cancellation
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      cancellation !== "none" ? (
                        <Radio checked={cancellation} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setCancellation(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      cancellation !== "none" ? (
                        <Radio checked={!cancellation} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setCancellation(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Additional member
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      additionalMember !== "none" ? (
                        <Radio checked={additionalMember} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setAdditionalMember(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      additionalMember !== "none" ? (
                        <Radio checked={!additionalMember} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setAdditionalMember(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Apply coupon code
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      applyCouponCode !== "none" ? (
                        <Radio checked={applyCouponCode} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setApplyCouponCode(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      applyCouponCode !== "none" ? (
                        <Radio checked={!applyCouponCode} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setApplyCouponCode(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Prescription section
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      prescription !== "none" ? (
                        <Radio checked={prescription} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setPrescription(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      prescription !== "none" ? (
                        <Radio checked={!prescription} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setPrescription(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  NPS rating
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      NPSRating !== "none" ? (
                        <Radio checked={NPSRating} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setNPSRating(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      NPSRating !== "none" ? (
                        <Radio checked={!NPSRating} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setNPSRating(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Login With
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={loginVia} // Set value based on loginVia state
                >
                  <FormControlLabel
                    value="E" // Value for Email
                    control={
                      <Radio
                        checked={loginVia === "EM"} // Check if loginVia is 'EM' for Email
                        onChange={() => setLoginVia("EM")} // Switch to Email
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    value="M" // Value for Phone
                    control={
                      <Radio
                        checked={loginVia === "MOB"} // Check if loginVia is 'PH' for Phone
                        onChange={() => setLoginVia("MOB")} // Switch to Phone
                      />
                    }
                    label="Phone Number"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  OTP authenticator
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  {showDataUpload === true ? (
                    <FormControlLabel
                      value={"PA"}
                      control={
                        OTPAuthenticator !== "" ? (
                          <Radio checked={OTPAuthenticator === "PA"} />
                        ) : (
                          <Radio />
                        )
                      }
                      label="Before serviceability check "
                      onClick={() => setOTPAuthenticator("PA")}
                    />
                  ) : (
                    <>
                      <FormControlLabel
                        value={"PA"}
                        control={
                          OTPAuthenticator !== "" ? (
                            <Radio checked={OTPAuthenticator === "PA"} />
                          ) : (
                            <Radio />
                          )
                        }
                        label="Before serviceability check "
                        onClick={() => setOTPAuthenticator("PA")}
                      />
                      <FormControlLabel
                        value={"AA"}
                        control={
                          OTPAuthenticator !== "" ? (
                            <Radio checked={OTPAuthenticator === "AA"} />
                          ) : (
                            <Radio />
                          )
                        }
                        label="After serviceability check"
                        onClick={() => setOTPAuthenticator("AA")}
                      />
                      <FormControlLabel
                        value={"NV"}
                        control={
                          OTPAuthenticator !== "" ? (
                            <Radio checked={OTPAuthenticator === "NV"} />
                          ) : (
                            <Radio />
                          )
                        }
                        label="Not required"
                        onClick={() => setOTPAuthenticator("NV")}
                      />
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Capture Lead
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      isCaptureLead !== "none" ? (
                        <Radio checked={isCaptureLead} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setIsCaptureLead(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      isCaptureLead !== "none" ? (
                        <Radio checked={!isCaptureLead} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setIsCaptureLead(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  show EmpId
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      showEmpId !== "none" ? (
                        <Radio checked={showEmpId} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Yes"
                    onClick={() => setShowEmpId(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      showEmpId !== "none" ? (
                        <Radio checked={!showEmpId} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="No"
                    onClick={() => setShowEmpId(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Summary MRP to be shown
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio checked={showMrpSummary} />}
                    label="Yes"
                    onClick={() => setShowMrpSummary(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio checked={!showMrpSummary} />}
                    label="No"
                    onClick={() => setShowMrpSummary(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Summary Go Live price to be shown
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio checked={showLivePrice} />}
                    label="Yes"
                    onClick={() => setShowLivePrice(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio checked={!showLivePrice} />}
                    label="No"
                    onClick={() => setShowLivePrice(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Package MRP to be shown
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio checked={isPkgMrp} />}
                    label="Yes"
                    onClick={() => setIsPkgMrp(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio checked={!isPkgMrp} />}
                    label="No"
                    onClick={() => setIsPkgMrp(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Package Go Live price to be shown
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio checked={isPkgLivePrice} />}
                    label="Yes"
                    onClick={() => setIsPkgLivePrice(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio checked={!isPkgLivePrice} />}
                    label="No"
                    onClick={() => setIsPkgLivePrice(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Select customer support
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={"CALL"}
                    control={
                      customerSupport !== "" ? (
                        <Radio checked={customerSupport === "CALL"} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Call"
                    onClick={() => setCustomerSupport("CALL")}
                  />
                  <FormControlLabel
                    value={"WHATSAP"}
                    control={
                      customerSupport !== "" ? (
                        <Radio checked={customerSupport === "WHATSAP"} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Whatsapp"
                    onClick={() => setCustomerSupport("WHATSAP")}
                  />
                </RadioGroup>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={"RED"}
                    control={
                      customerSupportBy !== "" ? (
                        <Radio checked={customerSupportBy === "RED"} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="By RCL"
                    onClick={() => setCustomerSupportBy("RED")}
                  />
                  <FormControlLabel
                    value={"CLIENT"}
                    control={
                      customerSupportBy !== "" ? (
                        <Radio checked={customerSupportBy === "CLIENT"} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="By Own"
                    onClick={() => setCustomerSupportBy("CLIENT")}
                  />
                </RadioGroup>
                {customerSupportBy === "CLIENT" && (
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    placeholder="Enter Number"
                    value={customerSupportNo}
                    onChange={(e) =>
                      String(e.target.value).length < 11 &&
                      setCustomerSupportNo(e.target.value)
                    }
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Payment mode
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      paymentMode !== "none" ? (
                        <Radio checked={paymentMode} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Subscription"
                    onClick={() => setPaymentMode(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      paymentMode !== "none" ? (
                        <Radio checked={!paymentMode} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Non-subscription"
                    onClick={() => setPaymentMode(false)}
                  />
                </RadioGroup>
                {!paymentMode && (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {!ecg_present && (
                      <FormControlLabel
                        value={"POST"}
                        control={
                          paymentModeType1 !== "" ? (
                            <Checkbox
                              checked={
                                paymentModeType1 === "POST" ? true : false
                              }
                            />
                          ) : (
                            <Checkbox />
                          )
                        }
                        label="COD"
                        onClick={() =>
                          paymentModeType1 === "POST"
                            ? setPaymentModeType1("")
                            : setPaymentModeType1("POST")
                        }
                      />
                    )}
                    <FormControlLabel
                      value={"PRE"}
                      control={
                        paymentModeType2 !== "" ? (
                          <Checkbox
                            checked={paymentModeType2 === "PRE" ? true : false}
                          />
                        ) : (
                          <Checkbox />
                        )
                      }
                      label="Prepaid"
                      onClick={() =>
                        paymentModeType2 === "PRE"
                          ? setPaymentModeType2("")
                          : setPaymentModeType2("PRE")
                      }
                    />
                  </RadioGroup>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Mobile number
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={
                      limit !== "none" ? <Radio checked={limit} /> : <Radio />
                    }
                    label="Limit"
                    onClick={() => setLimit(true)}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      limit !== "none" ? <Radio checked={!limit} /> : <Radio />
                    }
                    label="No - Limit"
                    onClick={() => setLimit(false)}
                  />
                </RadioGroup>
                {limit === true && (
                  <>
                    <TextField
                      key={limit}
                      variant="outlined"
                      size="small"
                      placeholder="No. of Bookings"
                      value={noOfBookings}
                      onChange={(e) => setNoOfBookings(e.target.value)}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <span style={{ padding: "10px" }}>Period :</span>
                      <FormControlLabel
                        value={true}
                        control={
                          period !== "none" ? (
                            <Radio checked={period} />
                          ) : (
                            <Radio />
                          )
                        }
                        label="Lifetime"
                        onClick={() => setPeriod(true)}
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          period !== "none" ? (
                            <Radio checked={!period} />
                          ) : (
                            <Radio />
                          )
                        }
                        label="No. of Days"
                        onClick={() => setPeriod(false)}
                      />
                    </RadioGroup>
                    {period === false && (
                      <TextField
                        key={period}
                        variant="outlined"
                        size="small"
                        placeholder="Period in days"
                        value={periodDays}
                        onChange={(e) => setPeriodDays(e.target.value)}
                      />
                    )}
                  </>
                )}
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={4}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              SMS/Whatsapp Communication
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value={true}
                control={<Checkbox />}
                label="SMS"
                onClick={() => setReschedule(true)}
              />
              <FormControlLabel
                value={false}
                control={<Checkbox />}
                label="Whatsapp"
                onClick={() => setReschedule(false)}
              />
            </RadioGroup>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Predefined Templates"
                onClick={() => setReschedule(true)}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Customized Template"
                onClick={() => setReschedule(false)}
              />
            </RadioGroup>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Select Predefined templates"
            />
          </FormControl>
        </Grid> */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} textAlign="center">
              {loading && submitted ? (
                <>
                  <Loader />
                  <br /> {editData.id ? "Updating" : "Submitting"}
                </>
              ) : (
                <>
                  {" "}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setTab(0)}
                    style={{ marginRight: "10px", width: "150px" }}
                  >
                    previous
                  </Button>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => submitCwa()}
                    style={{ width: "150px" }}
                    disabled={
                      packages.length === 0 ||
                      !campName ||
                      !startDate ||
                      (!noEndDate && !endDate)
                    }
                  >
                    {editData.id ? "update" : "Submit"}
                  </Button>
                </>
              )}
            </Grid>
          </>
        )}

        {/* <Grid item xs={12}><Divider/></Grid> */}
      </Grid>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  packagesList: state.ClientWebAppReducer.packagesList,
  allPackages: state.ClientWebAppReducer.allPackages,
  loading: state.ClientWebAppReducer.loading,
});

export default connect(mapStateToProps, {
  getPackages,
  getAllPackages,
  createCwa,
  updateCwa,
})(CWAForm);
