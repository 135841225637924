import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Grid, Button, Dialog } from "@mui/material";
import TableComponent from "../../components/tables/tableComponent";
import { getCwa, getCenterInfo ,uploadCampUsers } from "../../actions/ClientWebAppActions";
// import { getCentreCreationMasterForAdvanePayment } from '../actions/CollectionCenterAdmin';
import "../pages.sass";
import CWAForm from "../../components/forms/cwaForm";
import QRCode from "react-qr-code";
interface Props {
  getCwa: any;
  cwaList: any;
  loading: Boolean;
}
const CWA: React.FC<Props> = ({ getCwa, cwaList, loading }) => {
  const [page, setPage] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openBcode, setOpenBcode] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>("");
  const [qrCode, setQRCode] = useState<string>("");
 
  const editFunc = (data: any) => {
    setEditData(data)
    setOpen(true)
  };
  const closeBcode =()=>{
    setQRCode(``);
    setOpenBcode(false);
  }
  useEffect(() => {
    getCwa();
  }, []);
  const tableHead = [
    {
      head: "S.no.",
      key: "",
      type: "index",
    },
    {
      head: "WebApp ID",
      key: "id",
      type: "link",
    },
    {
      head: "Campaign name",
      key: "name",
      type: "string",
    },
    {
      head: "Created on",
      key: "created_at",
      type: "date",
    },
    {
      head: "Start date & time",
      key: "expire_start_time",
      type: "date_time",
    },
    {
      head: "End date & time",
      key: "expire_end_time",
      type: "date_time",
    },
  
    {
      head: "Action",
      key: <Button color="info" 
      onClick={(e: any) => {
        const availableData = JSON.parse(e.target.parentNode.parentNode.id);
        editFunc(availableData);
      }}
      >Edit</Button>,
      type: "function",
    },
  ];
  return (
    <main className="pageContainerCard">
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>{setEditData("") ;setOpen(true)}}
          >
            + Create New Web App
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            loading={loading}
            setPage={setPage}
            page={page}
            tableData={cwaList}
            tableHead={tableHead}
            getTableData={getCwa}
            tableType={"cwa"}

          />
        </Grid>
      </Grid>
      <Dialog open={open} fullScreen>
        <CWAForm setOpen={setOpen} editData={editData}/>
      </Dialog>
      <Dialog open={openBcode} onClose={closeBcode} maxWidth="lg">
        <div style={{padding:20,textAlign:"center"}}>
        <QRCode value={qrCode} size={250} />
        <br/>
        <br/>
        <a href={qrCode} target="_blank">Open Link: {qrCode}</a></div>
      </Dialog>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  centerInfo: state.ClientWebAppReducer.centerInfo,
  cwaList: state.ClientWebAppReducer.cwaList,
  loading: state.ClientWebAppReducer.loading,
});

export default connect(mapStateToProps, {
  getCwa,
  getCenterInfo
})(CWA);
