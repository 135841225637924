import React from "react";
import loader from "../loader/loader.png";
function Loader() {
  return (
    <div style={{width:"100%",padding:"15px"}}>
      <img src={loader} alt="Loading" style={{margin:"0px auto"}}/>
    </div>
  );
}

export default Loader;
