//Authentication
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "../Hoc/MainLayout";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import Dash from "../containers/dash";
import jwt_decode from "jwt-decode";
import { checkUser } from "../actions/loginActions";
import { connect } from "react-redux";
import { messageBox } from "../actions/notificationAction";

// Sample accessioning panel
import ClientWebAppDash from "../containers/ClientWebApp-agent";
import ClientWebAppAnalytics from "../ClientWebApp-Panel/pages/Analytics/index"
import ClientWebAppCWA from "../ClientWebApp-Panel/pages/ClientWA/index"
import OpdConfig from "../ClientWebApp-Panel/pages/Opd/index"
import DoctorManagement from "../ClientWebApp-Panel/pages/DoctorManagement";


function AuthenticatedRoutes(props) {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }
  const token = SecureStorage.getItem("token");
  // console.log(token, "token")
  // const decoded = decode(token);
  if (token) {
    return (
      <Routes>
        <Route
          path="/"
          element={<Dash path={path} query={query} />}
        />
      </Routes>)
  } else {
    return <Route render={() => <Navigate to="/" />} />;
  }
}

const HasRole = ({ children, role }) => {
  const token = SecureStorage.getItem("token");
  if (!token) {
    window.location = "/";
  }
  const decoded = jwt_decode(token);
  if (decoded.user_group !== role) {
    window.location = "/dashboard";
  }else{
    return children;
  }
  return <div>Redirecting....</div>;
}

function AppRoutes(props) {

  return (
    <BrowserRouter>
      <MainLayout>
        <SnackBar />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="dashboard"
            element={<AuthenticatedRoutes />}
          />
          <Route
            path="dashboard/cwa/*"
            element={
              <HasRole role="CorPartner">
                <ClientWebAppDash>
                  <Routes>
                    {/* <Route
                      path="/"
                      element={<ClientWebAppAnalytics />}
                    /> */}
                    <Route
                      path="/"
                      element={<ClientWebAppCWA />}
                    />
                     <Route
                      path="/opd-configurations"
                      element={<OpdConfig />}
                    />
                     <Route
                      path="/doctor-appoinments"
                      element={<DoctorManagement />}
                    />
                  
                    

                  </Routes>
                </ClientWebAppDash>
              </HasRole>
            } />
        </Routes>
        {/* <Modal /> */}
        {/* <SecondModal /> */}
      </MainLayout>
    </BrowserRouter>
  );
}
export default connect(null, { checkUser, messageBox })(AppRoutes);