import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Button, Dialog } from "@mui/material";
import TableComponent from "../../components/tables/tableComponent";
import { getDocAppoinments } from "../../actions/ClientWebAppActions";
import "../pages.sass";
import QRCode from "react-qr-code";
import OpdForm from "../../components/forms/opdForm";
interface Props {
  getDocAppoinments: any;
  docAppoinmentList: any;
  loading: Boolean;
}
const DoctorManagement: React.FC<Props> = ({ getDocAppoinments, docAppoinmentList, loading }) => {
  const [page, setPage] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openBcode, setOpenBcode] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>("");
  const [qrCode, setQRCode] = useState<string>("");
 
  const editFunc = (data: any) => {
    setEditData(data)
    setOpen(true)
  };
  const closeBcode =()=>{
    setQRCode(``);
    setOpenBcode(false);
  }
  useEffect(() => {
    getDocAppoinments();
  }, []);
  let allData:any = []
  useEffect(() => {
    if(docAppoinmentList?.results&&docAppoinmentList?.results.length>0){
      docAppoinmentList?.results.map((val:any)=>allData.push({
        "appoinment_date":val.appoinment_date,
        "doctor_name":val?.doctor?.doctor_name,
        "full_name":val?.customer?.full_name,
        "age":val?.customer?.age,
        "customer_gender":val?.customer?.customer_gender,
        "status":val?.status,
    
      }))
    }
  }, [docAppoinmentList])
  
  const tableHead = [
    {
      head: "S.no.",
      key: "",
      type: "index",
    },
    {
      head: "Appoinment date",
      key: "appoinment_date",
      type: "date",
    },
    {
      head: "Doctor name",
      key: "doctor_name",
      type: "string",
    },
    {
      head: "Patient Name",
      key: "full_name",
      type: "string",
    },
    {
      head: "Patient Age",
      key: "age",
      type: "string",
    },
    {
      head: "Patient Gender",
      key: "customer_gender",
      type: "string",
    },
    {
      head: "Status",
      key: "status",
      type: "string",
    },
  ];
  return (
    <main className="pageContainerCard">
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>{setEditData("") ;setOpen(true)}}
          >
            + Create New OPD
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            loading={loading}
            setPage={setPage}
            page={page}
            tableData={{...docAppoinmentList,"data":allData}}
            tableHead={tableHead}
            getTableData={getDocAppoinments}
            tableType={"opd"}
          />
        </Grid>
      </Grid>
      <Dialog open={open} fullScreen>
        <OpdForm setOpen={setOpen} editData={editData}/>
      </Dialog>
      <Dialog open={openBcode} onClose={closeBcode} maxWidth="lg">
        <div style={{padding:20,textAlign:"center"}}>
        <QRCode value={qrCode} size={250} />
        <br/>
        <br/>
        <a href={qrCode} target="_blank">Open Link: {qrCode}</a></div>
      </Dialog>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  docAppoinmentList: state.ClientWebAppReducer.docAppoinmentList,
  loading: state.ClientWebAppReducer.loading,
});

export default connect(mapStateToProps, {
  getDocAppoinments,
})(DoctorManagement);
