import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import logo from "../../images/logo1.png";
import banner from "../../images/banner.svg";
import "./index.sass";
import LoginForm from "../../components/LoginForm";
import { login } from "../../actions/loginActions";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state: any) => state.loginReducer);

  const handleForm = async (form: object) => {
    try {
      await dispatch(login(form));
      navigate("/dashboard");
    } catch (error) {
      return error;
    }
  };
  return (
    <Grid container>
      <Grid item md={6} xs={12}>
        <Grid container  >
          <Grid item xs={12} style={{ padding: 15 }}>
            <img src={logo} height="50" />
          </Grid>
          <Grid item xs={12} className="container2" style={{ padding: 15 }}>
            <div className="center">
              <LoginForm loading={loading} handleForm={handleForm} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12} style={{ background: "#1F1F1F" }}>
        <div className="container">
          <img src={banner} style={{padding:"20%"}} className="center" />
        </div>
      </Grid>
    </Grid>
  );
}
