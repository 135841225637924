import {
  ACTIONS,
  ClientWebAppTypes,
} from "../interfaces/actionTypes/ClientWebApp";

type InitialState = {
  loading: boolean;
  centerInfo: Array<any>;
  userDetails: Array<any>;
  cwaList: Object;
  packagesList: Object;
  allPackages: Object;
  opdList: Object;
  docAppoinmentList: Object;
  uploadData:Object;
};

const initialState: InitialState = {
  loading: false,
  centerInfo: [],
  userDetails: [],
  cwaList: {},
  packagesList: {},
  allPackages: {},
  opdList: {},
  docAppoinmentList: {},
  uploadData:{}
};

const ClientWebAppReducer = (
  state = initialState,
  action: ClientWebAppTypes
) => {
  switch (action.type) {
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };

    case ACTIONS.GET_CWA:
      return {
        ...state,
        cwaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOC_APPOINMENT:
      return {
        ...state,
        docAppoinmentList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packagesList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES_ALL:
      return {
        ...state,
        allPackages: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
      case ACTIONS.UPLOAD_CAMP_USER:
        return {
          ...state,
          uploadData: action.payload,
          loading: false,
        };  
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.GET_OPD:
      return {
        ...state,
        opdList: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default ClientWebAppReducer;
