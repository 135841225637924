import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo1.png";
import logo2 from "../../images/logo.svg";
import { logout, panelUserLogout } from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { changePassword } from "../actions/ClientWebAppActions";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import DashboardIcon from '@mui/icons-material/Dashboard';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Fade from "@mui/material/Fade";
import "./index.sass";
import analyticsIcon from "../icons/analytics.svg";
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import cwa from "../icons/cwa.svg";
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - ${80}px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth+10,
    width: `calc(100% - ${drawerWidth+10}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface Props {
  passwordStatus: boolean;
}

const SampleAcceDashboard: React.FC<Props> = ({ passwordStatus }) => {
  let dispatch = useDispatch();
  const [changePasswordModal, setchangePasswordModal] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [heading, setHeading] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const theme = useTheme();
  const [open, setOpen] = useState<any>(false);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!passwordStatus) {
      setchangePasswordModal(true);
    }
  }, [passwordStatus]);

  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
    dispatch(logout());
  };

  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };
  const location = useLocation();
  return (
    <Box sx={{ display: "flex", background: "#f3f4f7" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} id="mainBox">
        <Toolbar>
          <div style={{ width: "30%", display: "flex" }}>
            {open ? (
              <IconButton
              color="inherit"
              aria-label="close drawer"
                onClick={handleDrawerClose}
                className="iconBackground"
                edge="start"
                sx={{
                  marginRight: 5,
                }}
              >
                  <MenuIcon color="primary"/>
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                className="iconBackground"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                }}
              >
                <MenuIcon color="primary"/>
              </IconButton>
            )}

            <Typography variant="h5" noWrap component="div">
             {heading}
            </Typography>
          </div>
          <div style={{ width: "70%", textAlign: "right" }}>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
            >
              {localStorage.getItem("USER_NAME")}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Dialog
          open={changePasswordModal}
          onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <DrawerHeader>
          <div style={{ textAlign: "left", width: "100%",marginLeft:16 }}>
            {open? <img src={logo} height="50"  />: <img src={logo2} height="50" />}
           
          </div>
        </DrawerHeader>
        {!open ? "" : <span className="menuName">MAIN MENU</span>}

        <List>
          {/* <Link to="/dashboard/cwa" onClick={()=>setHeading("Analytics")}>
            <ListItem>
              <Tooltip title="Analytics" arrow>
                <ListItemIcon>
                  <div className="iconBackground">
                    <img src={analyticsIcon} width="25px" />
                  </div>
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary="Analytics"
                style={{
                  color:
                    location.pathname === "/dashboard/cwa"
                      ? "#f02633ab"
                      : "#6F7580",
                }}
              />
            </ListItem>
          </Link> */}
          <Link to="/dashboard/cwa" onClick={()=>setHeading("Client Web App")}>
            <ListItem>
              <Tooltip title="Client WebApp" arrow>
                <ListItemIcon>
                  <div className="iconBackground">
                    <DashboardIcon />
                  </div>
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary="Client WebApp"
                style={{
                  color:
                    location.pathname === "/dashboard/cwa"
                      ? "#f02633ab"
                      : "#6F7580",
                }}
              />
            </ListItem>
          </Link>
          {/* <Link to="/dashboard/cwa/opd-configurations" onClick={()=>setHeading("Opd configurations")}>
            <ListItem>
              <Tooltip title="Opd configurations" arrow>
                <ListItemIcon>
                  <div className="iconBackground">
                    <AppSettingsAltIcon />
                  </div>
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary="Opd configurations"
                style={{
                  color:
                    location.pathname === "/dashboard/cwa/opd-configurations"
                      ? "#f02633ab"
                      : "#6F7580",
                }}
              />
            </ListItem>
          </Link>
          <Link to="/dashboard/cwa/doctor-appoinments" onClick={()=>setHeading("Doctor Appoinment")}>
            <ListItem>
              <Tooltip title="Doctor Appoinment" arrow>
                <ListItemIcon>
                  <div className="iconBackground">
                    <NoteAddIcon />
                  </div>
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary="Doctor Appoinment"
                style={{
                  color:
                    location.pathname === "/dashboard/cwa/doctor-appoinments"
                      ? "#f02633ab"
                      : "#6F7580",
                }}
              />
            </ListItem>
          </Link> */}
        
        </List>

       
      </Drawer>
    </Box>
  );
};
const mapStateToProps = (state: any) => ({
  passwordStatus: state.loginReducer.passwordStatus,
});

export default connect(mapStateToProps, {})(SampleAcceDashboard);
