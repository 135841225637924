export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_USER_DETAILS: "cwa/get_user_details",
  SET_LOADING: "cwa/set_loading",
  GET_CWA: "cwa/data/",
  GET_PACKAGES: "cwa/packages",
  GET_PACKAGES_ALL: "cwa/packages/all",
  GET_CENTER_INFORMATION: "cwa/centre",
  UPLOAD_CAMP_USER:"cwa/camp_upload",
  CREATE_CWA: "cwa/create",
  GET_OPD: "opd/data",
  GET_DOC_APPOINMENT: "opd/doc_appoinment",
};

interface UploadCamp {
  type: typeof ACTIONS.UPLOAD_CAMP_USER;
  payload: Object;
}

interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Object;
}
interface GetDocAppoinment{
  type: typeof ACTIONS.GET_DOC_APPOINMENT;
  payload: Object;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetAdvancePayment {
  type: typeof ACTIONS.GET_CWA;
  payload: Array<any>;
}
interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: boolean;
}
interface GetPackagesAll {
  type: typeof ACTIONS.GET_PACKAGES_ALL;
  payload: boolean;
}
interface CreateCwa {
  type: typeof ACTIONS.CREATE_CWA;
  payload: boolean;
}
interface GetOpd {
  type: typeof ACTIONS.GET_OPD;
  payload: boolean;
}

export type ClientWebAppTypes =
  | SetLoading
  | ChangePassword
  | GetCenterInfo
  | GetAdvancePayment
  | GetPackages
  | UploadCamp
  | GetPackagesAll
  | CreateCwa
  | GetOpd
  | GetDocAppoinment
  | GetUserDetails;
