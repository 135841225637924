import React from "react";
import ClientWebAppDashboardLeft from "../../ClientWebApp-Panel/dashboard-left/index";
import ClientWebAppDashboardRight from "../../ClientWebApp-Panel/dashboard-right/index";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
    palette: {
      primary: {
        main: "#E83E29",
      },
      secondary: {
        main: "#2677F0",
      },
    },
  });
interface Props {
    children: any;
}

const LimsAdminDash: React.FC<Props> = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
        <div style={{ display: 'flex',padding: "20px"}}>
            <ClientWebAppDashboardLeft />
            <ClientWebAppDashboardRight>{children && children}</ClientWebAppDashboardRight>
        </div></ThemeProvider>
    );
};

export default LimsAdminDash;

