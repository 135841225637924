import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Loader from "../../components/loader";
import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";
import moment from "moment";
import "../../pages/pages.sass";
import { uploadCampUsers } from "../../actions/ClientWebAppActions";

interface Props {
  setPage: any;
  page: any;
  tableData: any;
  getTableData: any;
  tableHead: any;
  loading: Boolean;
  tableType: any;
  uploadCampUsers:any;
}
const TableComponent: React.FC<Props> = ({
  setPage,
  page,
  tableData,
  getTableData,
  tableHead,
  loading,
  tableType,
  uploadCampUsers
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = tableData && tableData?.next.split("?")[1];
      getTableData(url);
    } else if (newPage < page) {
      let url = tableData && tableData?.previous.split("?")[1];
      getTableData(url);
    }
    setPage(newPage as number);
  };
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    if (tableData?.data) {
      setData(tableData?.data);
    } else if (tableData?.results) {
      setData(tableData?.results);
    }
  }, [tableData]);

  const [uploadData, setUploadData] = useState<any>("");
  const [file, setFile] = React.useState<any>("");
  const [file_name, setFileName] = React.useState<string>("");

  const handleFileChange = (data: any) => {
    setFile(data[0]);
    setFileName(data[0].name);
  };
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = useRef<any>(null);

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFileChange(e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e.dataTransfer.files);
    }
  };



  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("file", file);
    
    if (uploadData) {
      await uploadCampUsers(formData, uploadData?.id);
    }
    setFile("");
    setFileName("");
    setUploadData("");
  };
  
  return (
    <>
      <TableContainer
        className={"tableContainer"}
        style={{ maxHeight: "70vh" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHead.map((val: string, index: number) => (
                  <>
                    <TableCell key={index} align="center">
                      {val["head"]}
                    </TableCell>
                  </>
                ))}
                <TableCell align="center">{"Data upload"}</TableCell>
                <TableCell align="center">{"ECG"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 &&
                data.map((payment: any, index1: number) => {
                  return (
                    <TableRow key={index1} id={JSON.stringify(payment)}>
                      {tableHead.map((val: string, index: number) => (
                        <TableCell key={index} align="center">
                          {val["type"] === "string" ? (
                            payment[val[`key`]]
                          ) : val["type"] === "boolean" ? (
                            payment[val[`key`]] ? (
                              "yes"
                            ) : (
                              "no"
                            )
                          ) : val["type"] === "date" ? (
                            moment(payment[val[`key`]]).format("DD-MM-YY")
                          ) : val["type"] === "date_time" ? (
                            (payment[val[`key`]] &&
                              moment(payment[val[`key`]]).format(
                                "DD-MM-YY, HH:MM"
                              )) ||
                            ""
                          ) : val["type"] === "index" ? (
                            index1 + 1
                          ) : val["type"] === "link" ? (
                            <a
                              href={
                                process.env.REACT_APP_ENV === "production"
                                  ? `https://${
                                      tableType === "opd"
                                        ? "opd"
                                        : "iframepartner"
                                    }.redcliffelabs.com/?pid=${
                                      payment[val[`key`]]
                                    }`
                                  : `https://${
                                      tableType === "opd"
                                        ? "opddev"
                                        : "iframepartnerdev"
                                    }.redcliffelabs.com/?pid=${
                                      payment[val[`key`]]
                                    }`
                              }
                              target="_blank"
                            >
                              {payment[val[`key`]]}
                            </a>
                          ) : (
                            val["key"]
                          )}
                        </TableCell>
                      ))}
                      <TableCell key={index1} align="center">
                        <Button
                          disabled={!payment?.allow_upload}
                         
                          color="success"
                          onClick={() => {
                            setUploadData(payment);
                          }}
                        >
                          <UploadFileTwoToneIcon />
                        </Button>
                      </TableCell>
                      <TableCell key={index1} align="center">
                        {payment?.package_details?.some(
                          (item: any) => item?.package_center_prices?.is_ecg
                        )
                          ? "Yes"
                          : "No"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                {tableData?.count > 0 ? (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={2}
                    count={tableData?.count || 0}
                    rowsPerPage={tableData?.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                ) : (
                  "No Data Found!"
                )}
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <Dialog
        open={uploadData?.id ? true : false}
        onClose={() => setUploadData("")}
      >
        <form
          id="form-file-upload"
          onDragEnter={(e) => handleDrag(e)}
          onDragLeave={(e) => handleDrag(e)}
          onDragOver={(e) => handleDrag(e)}
          onDrop={(e) => handleDrop(e)}
          onSubmit={(e) => e.preventDefault()}
          style={{ padding: 15 }}
        >
          <input
            ref={inputRef}
            type="file"
            accept=".csv"
            id="input-file-upload"
            multiple={false}
            onChange={(e) => handleChange(e)}
            hidden
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            style={{
              padding: "50px",
              border: "2px dashed #86C8BC",
              borderRadius: "20px",
              textAlign: "center",
              width: "400px",
              backgroundColor: dragActive ? "white" : "rgb(243 255 253)",
            }}
          >
            <div>
              <InsertDriveFileIcon
                color="success"
                style={{ fontSize: "40px" }}
              />
              <br />
              <span style={{ fontSize: "14px", color: "#a4a4a4" }}>
                Drag &amp; Drop your file here
              </span>
              <br />
              <span style={{ color: "gray", fontWeight: "bold" }}>OR</span>
              <br />
              <Button
                variant="outlined"
                size="small"
                color="success"
                onClick={(e) => onButtonClick()}
              >
                Click to select file
              </Button>
              <br />
              <p className="mt-3">
                {" "}
                {file_name
                  ? `Selected file: ${file_name}`
                  : "No file selected!!"}
              </p>
            </div>
          </label>
          <hr />
          <Button
            variant="outlined"
            color="success"
            // disabled={file === "" || loading}
            onClick={() => uploadFile()}
          >
            Save
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            variant="outlined"
            color="error"
            onClick={() => {
              setFile("");
              setFileName("");
              setUploadData("");
              // setCampUploadId("");
            }}
          >
            Close
          </Button>
          <a
            href="https://static-redcliffelabs.s3.amazonaws.com/media/ppmc-data/f3a7da85-a7c9-4e21-b370-64162447a4ad.csv"
            download={true}
          >
            <Button
              style={{ marginLeft: "13%" }}
              variant="outlined"
              color="info"
            >
              Dowload format
            </Button>
          </a>
        </form>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {uploadCampUsers})(TableComponent);
